<template>

  <div class="collapse menu-collapse" id="collapseSports" data-parent="#accordion" >

    <div class="menu-accordion p-2">

      <div class="row">


           <div class="col-6" v-for="(m,index) in sports" v-bind:key="getKey(index)">
            <router-link :to="'/sports/'+m.sport_name+'/'+m.sport_id">
              <div class="item-wrapper" @click="setSport(m.sport_id)">
                  <img src="/img/sports/Soccer.svg">
                  <span v-bind:class="getMenuTextClass(m.sport_id)"> {{ m.sport_name }} </span>
              </div>
            </router-link>
          </div>
    

      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "sports",
  methods: {

    getKey: function(index){

      return Math.random().toString(11).replace('0.', 'sport-'+index + '-');

    },
    setSport: function (sportID) {

      document.getElementById("sports-btn").click();
      this.$store.dispatch("setSportID",sportID);

      //if(this.$store.state.current_page === 'home')
        //return

      //this.$router.push({name: 'home'});
    },
    getMenuTextClass: function (sportID){

      return parseInt(this.$store.state.sport_id) === parseInt(sportID) ? "ml-2 item-wrapper-span-active" : "ml-2 item-wrapper-span";

    },
    isActiveMenu: function (name){

      return this.current_page === name ? "menu-active" : "";

    },
    resetGames: function(){

      this.$store.dispatch("resetAllGames");

      //if(this.current_page !== "home") {

      this.goHome();

      //}

    }
  },
  computed: {
    iconSize: function (){

      return 20;

    },
    current_page: function () {

      return this.$store.state.current_page
    },
    show_freebet: function (){

      var p = this.getProfile();
      if(!p) {

        return true;

      }

      return parseInt(p.b4) > 0;

    },
    sports: function() {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s,function(k,v){

        if(v.sport_id > 1 ) {

          t.push(v);
        }

      })

      return t;
    },
  },
}
</script>

<style scoped>

</style>